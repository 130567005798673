import { Doc } from "@/types/types";
import { Commit } from "vuex";

interface State {
  filesToDelete:string[]
}

export const FileStore = {
  state:() => ({
    files : {
      avatar:[],
      id: [],
      scolarshipCert: [],
      scolarshipCertN: [],
      warrantId: [],
      taxDoc: [],
      cdiSpe1: [],
      cdiSpe2: [],
      unemployedSpe: [],
      independantSpe: [],
      retiredSpe: [],
    },
    filesToDelete:[]
  }),

  getters : {
    getFiles : (state:any) => state.files,
    getFilesToDelete : (state:any) => state.filesToDelete
  },

  actions : {
    setFiles({commit}:{commit:Commit}, payload:Doc[]){
      commit('SET_FILES', payload)
    },
    removeFile({commit}:{commit:Commit}, payload:{fileId:string, fileType:string,fileIndex:number}){
      const {fileId, fileType, fileIndex} = payload;
      commit('REMOVE_FILE', { fileId, fileType, fileIndex });
    },
    addFile({commit}:{commit:Commit}, payload:Doc){
      console.log('fileToAdd:',payload);
      commit('ADD_FILE', payload);
    },
    removeFromFilesToDelete({commit}:{commit:Commit}, payload:string){
      commit('REMOVE_FROM_TO_DELETE', payload);
    },
  },

  mutations : {
    SET_FILES(state:any, payload:Doc[]){
      for (const file of payload){
        state.files[file.fileType].push(file);
      }
    },
    REMOVE_FILE(state:any, payload:{fileId:string, fileType:string, fileIndex:number}){
      const { fileType, fileIndex, fileId } = payload;
      state.files[fileType].splice(fileIndex, 1);
      state.filesToDelete.push(fileId);
    },
    ADD_FILE(state:any, payload:Doc){
      state.files[payload.fileType].push(payload);
    },
    REMOVE_FROM_TO_DELETE(state:State, payload:string){
      const index = state.filesToDelete.findIndex(file => file === payload);
      if (index !== -1) state.filesToDelete.splice(index, 1);
    },

    SET_FILES_LOGOUT(state:any){
      state.files = {
        avatar:[],
        id: [],
        scolarshipCert: [],
        scolarshipCertN: [],
        warrantId: [],
        taxDoc: [],
        cdiSpe1: [],
        cdiSpe2: [],
        unemployedSpe: [],
        independantSpe: [],
        retiredSpe: [],
      },
      state.filesToDelete = []
    }
  }
}