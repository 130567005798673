import { CookieUtils } from "@/services/cookies";
import axios from "axios";

const baseUrl = process.env.NODE_ENV === 'development' 
  ? "http://localhost:3000/api/v1"
  : "https://api.keysper.fr/api/v1"

const api = axios.create({
  baseURL: baseUrl,
  withCredentials:true,
});

api.interceptors.request.use((config) => {
  const skipAuthUrls = ['auth/login', 'student-login', 'cookies-login','signup'];
  if (!config.url) return config;
  if (skipAuthUrls.some(url => config.url?.endsWith(url))) return config;
  const token = config.url.endsWith('refresh') 
    ? CookieUtils.getToken().refreshToken 
    : CookieUtils.getToken().accessToken;
  config.headers.Authorization = `Bearer ${token}`
  return config;
}, error => Promise.reject(error));


api.interceptors.response.use(response => response, async (error) => {
  const originalRequest = error.config;
  const skipRetryUrls = ['auth/login', 'student-login', 'auth/logout','signup','auth/refresh'];
  if (skipRetryUrls.some(url => originalRequest.url.endsWith(url))) {
    return Promise.reject(error);
  }
  if (error.response.status === 401){
    if (!originalRequest._retry){
      try {
        originalRequest._retry = true;
        const response = await api.post('auth/refresh');
        const newToken = response.data.data.accessToken
        if (response.status===200){
          CookieUtils.saveToken(response.data.data);
        }
        api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return api(originalRequest)
      } catch (error){
        console.error('Error refreshing token:', error);
        return Promise.reject(error)
      }
    }
  }
  return Promise.reject(error);
});

export default api;