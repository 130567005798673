import { Apartment } from "@/types/types";
import { Commit } from "vuex";

interface State {
  compatibleFullApartments: Apartment[]
  compatibleApartments: string[]
  lastSeenApartments: Apartment[]
}

export const SwipeStore = {
  state:() => ({
    compatibleApartments: [],
    compatibleFullApartments: [],
    lastSeenApartments: [],
    lastActions: []
  }),

  getters : {
    getCompatibleFullApartments : (state:any) => state.compatibleFullApartments,
    getLastSeenApartments : (state:any) => state.lastSeenApartments,
    getLastAction : (state:any) => state.lastActions[state.lastActions.length-1],
    getAllCompatibleApartmentsLength : (state:any) => state.compatibleApartments.length,
  },

  actions : {
    // login
    setCompatibleFullApartments({commit}:{commit:Commit}, payload:Apartment[]){
      // preloadImages(payload);
      commit('SET_COMPATIBLE_FULL_APARTMENTS', payload);
    },
    setAllCompatibleApartments({commit}:{commit:Commit}, payload:any[]){
      commit('SET_ALL_COMPATIBLE_APARTMENTS', payload);
    },
    // browse
    updateAfterSwipe({commit}:{commit:Commit}, payload:{apartment:Apartment, action:string}){
      const {apartment, action} = payload;
      console.log('update after swipe:', apartment, action)
      if (action==='liked') commit('UPDATE_LIKED_APARTMENTS', apartment);
      commit('UPDATE_LAST_SEEN_APARTMENTS', apartment);
      commit('INCREMENT_APARTMENTS_SEEN');
    },
    updateAfterRewind({commit}:{commit:Commit}, payload:Apartment){
      commit('POP_LAST_ACTION');
      commit('POP_LAST_SEEN_APARTMENT', payload);
    },
    pushLastAction({commit}:{commit:Commit}, payload:string){
      commit('PUSH_LAST_ACTION', payload);
    }
  },

  mutations : {
    // login
    SET_ALL_COMPATIBLE_APARTMENTS(state:any, payload:any[]){
      state.compatibleApartments = payload.map(a => a.id);
    },
    SET_COMPATIBLE_FULL_APARTMENTS(state:any, payload:Apartment[]){
      state.compatibleFullApartments = payload;
    },

    // browse
    UPDATE_LAST_SEEN_APARTMENTS(state:State, payload:Apartment){
      state.lastSeenApartments.push(payload)
      const simpleIdx = state.compatibleApartments.findIndex(id => id === payload._id);
      if (simpleIdx !== -1) state.compatibleApartments.splice(simpleIdx, 1);
      const fullIdx = state.compatibleFullApartments.findIndex(apt => apt._id === payload._id);
      if (fullIdx !== -1) state.compatibleFullApartments.splice(fullIdx, 1);
    },
    POP_LAST_SEEN_APARTMENT(state:any, payload:Apartment){
      state.compatibleApartments.push(payload._id);
      state.compatibleFullApartments.push(payload);
      state.lastSeenApartments.pop();
    },
    PUSH_LAST_ACTION(state:any, payload:string){
      state.lastActions.push(payload);
    },
    POP_LAST_ACTION(state:any){
      state.lastActions.pop();
    },

    SET_SWIPE_LOGOUT(state:any){
      state.compatibleApartments = []
      state.compatibleFullApartments = []
      state.lastSeenApartments = []
      state.lastActions = []
    }
  }
}

// export const preloadImages = (apartments: Apartment[]) => {
//   apartments.forEach(apartment => {
//     apartment.images.forEach(imageUrl => {
//       const img = new Image();
//       img.src = imageUrl;
//       console.log('fetched!')
//     });
//   });
// };