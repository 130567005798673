import { messaging } from "@/services/firebaseInit";
import { getToken } from "firebase/messaging";
import { Register } from "@/api/requests";

export const requestPermissionAndSendNotificationToken = async () => {
  const maxRetries = 5;
  const retryDelay = 3000; // 5 seconds in milliseconds
  let retries = 0;
  console.log('inside request')
  while (retries < maxRetries) {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') return;
      const currentToken = await getTokenWithTimeout(retryDelay);
      if (currentToken) {
        await Register.postFcmToken(currentToken as string);
        return;
      }
      console.log('No registration token available. Request permission to generate one.');
    } catch (e) {
      console.log('An error occurred while retrieving token. ', e);
      retries++;
      console.log(`Retrying... Attempt ${retries} of ${maxRetries}`);
    }
  }
  console.log('Max retries reached. Unable to retrieve token.');
}

const getTokenWithTimeout = async (timeout:number) => {
  return new Promise(async (resolve, reject) => {
    setTimeout(async () => {
      try {
        const currentToken = await getToken(messaging, { vapidKey: 'BLs5ViMPE7k5VB2R4BFptXakrzasCEVgQFkojyInLrzOYMA29TO41ee11D-obYbYLId2UEdn29yGih8LPNZUURY' });
        resolve(currentToken);
      } catch (e) {
        reject(e);
      }
    }, timeout);
  });
}