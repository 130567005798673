import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3Tour from 'vue3-tour';
import store from '@/store/index'

import 'vue3-tour/dist/vue3-tour.css';



const app = createApp(App);

import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import vue3GoogleLogin from 'vue3-google-login'

const customLight = {
  dark: true,
  colors: {
    background: "#222222",
    primary: "#0097b2",
    alert: "#d95757",
    warning: "#f6ad56",
    success: "#57D99E",
    comp: "#383838",
    inactive: "#434242ff"
  }
}

import { VBtn } from 'vuetify/components';

const vuetify = createVuetify({
  components,
  directives,
  aliases : {
    VBtnAction:VBtn
  },
  defaults: {
    VBtn: { class: "text-capitalize rounded-ml", elevation:6, variant:'elevated' },
    VCard: { class: "rounded-xl" },
    VTextField: { variant: "solo-filled", density: 'compact' },
    VSelect: { variant: "solo-filled", density: 'compact' },
    VBtnAction : { class: 'white-btn', elevation:6, variant:'elevated', rounded:'xl' }
  },
  theme: {
    defaultTheme: 'customLight',
    themes: {
      customLight
    }
  },
});

app
.use(router)
.use(store)
.use(vuetify)
.use(vue3GoogleLogin, {clientId:'866571093555-i4ebboi50fc2ndqfn8tassfsoh771iko.apps.googleusercontent.com'})
.use(Vue3Tour)
.mount("#app");