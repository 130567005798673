import { Commit } from "vuex";

interface AuditRating {
  rating:number
  percentage:number 
  comment:string
  title:string
  color:string
}

export type Audit = Record<string,AuditRating>;

export const AuditStore = {
  state:() => ({
    total : 0,
    warrantIsKnown: false,
    noValueAtZero: false,
    warrantAudit : {},
    searchAudit : {}
  }),

  getters : {
    getTotal : (state:any) => scoreToLetter(state),
    getSearchAudit : (state:any) => state.searchAudit,
    getWarrantAudit : (state:any) => state.warrantAudit,
    getWarrantIsKnown : (state:any) => state.warrantIsKnown
  },

  actions : {
    setAudit({commit}:{commit:Commit}, payload:any){
      commit('SET_TOTAL', payload['total'])
      commit('SET_IF_VALUE_AT_ZERO', payload['noValueAtZero'])
      commit('SET_AUDIT_SEARCH', payload['content']['profile']['report']);
      commit('SET_AUDIT_WARRANT', payload['content']['warrant']['report']);
      commit('SET_WARRANT_IS_KNOWN', payload['matchingAllowed'])
    },
  },

  mutations : {
    SET_AUDIT_SEARCH(state:any, payload:Audit) {
      state.searchAudit = payload; // deepMerge(deepMerge({}, state.searchAudit), payload);
    },
    SET_AUDIT_WARRANT(state:any, payload:Audit) {
      state.warrantAudit = payload;
    }, 
    SET_TOTAL(state:any, payload:number) {
      state.total = payload;
    },
    SET_WARRANT_IS_KNOWN(state:any, payload:boolean){
      state.warrantIsKnown = payload;
    },
    SET_IF_VALUE_AT_ZERO(state:any, payload:boolean){
      state.noValueAtZero = payload;
    }
  }
}

const scoreToLetter = (state:any) => {
  if (!state.noValueAtZero) return {letter:'F',color:'alert'}
  if (state.total > 80) return {letter:'A',color:'success'}
  if (state.total > 60) return {letter:'B',color:'success'}
  if (state.total > 45) return {letter:'C',color:'warning'}
  if (state.total > 30) return {letter:'D',color:'warning'}
  if (state.total > 15) return {letter:'E',color:'alert'}
  return { letter:'F',color:'alert'}
}