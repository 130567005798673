// src/services/firebaseInit.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCstGTcfQSnRGRxjWo82e1JmQJl4nmo8dY",
    authDomain: "keysper-pwa-afe11.firebaseapp.com",
    projectId: "keysper-pwa-afe11",
    storageBucket: "keysper-pwa-afe11.appspot.com",
    messagingSenderId: "866571093555",
    appId: "1:866571093555:web:01945d64cba157b6e3c546",
    measurementId: "G-7BWXC2K5P0"
  };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app, messaging };