<template>
  <v-app>
    <top-bar v-if="shouldShowBars"/>
    <bottom-bar v-if="shouldShowBars"/>
    <!-- <tour-card v-if="showTour"/> -->
    <v-main>
      <router-view/>
    </v-main>
    <div class="text-center ma-2">
      <v-snackbar 
        class="mb-16 custom-snackbar" 
        v-model="showSnackbar" 
        :color="snackbarData.color"
        multi-line
      >
        <v-list dense class="custom-list">
          <v-list-item
            v-for="(item, index) in snackbarData.text"
            :key="index"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const TopBar = defineAsyncComponent(() => import("@/components/TopBar.vue"));
const BottomBar = defineAsyncComponent(() => import("@/components/BottomBar.vue"));
// const TourCard = defineAsyncComponent(() => import("@/components/TourCard.vue"));

const store = useStore();
const route = useRoute();

const showSnackbar = computed(() => store.getters.shouldShowSnackbar);
const snackbarData = computed(() => store.getters.getSnackbar);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
// const showTour = computed(() => store.getters.showTutorial && store.getters.canStartTour)

const shouldShowBars = computed(() => isLoggedIn.value && !['/intro', '/warrant-intro'].includes(route.path));
</script>

<style>
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.white-btn {
  font-weight:300;
  color:white !important;
  background: rgb(120,210,157);
  background: linear-gradient(11deg, rgba(120,210,157,1) 28%, rgba(75,160,102,1) 55%);  
  text-transform: unset !important;
}

.custom-snackbar .v-list.custom-list {
  background-color: inherit; /* Inherit the background color from the snackbar */
}
</style>