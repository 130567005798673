import { createStore } from "vuex";
import { SnackbarStore } from "./modules/snackbar";
import { SessionStore } from "./modules/session";
import { UserStore } from "./modules/user";
import { SwipeStore } from "./modules/swipe";
import { FileStore } from "./modules/files";
import { AuditStore } from "./modules/audit";
import { TourStore } from "./modules/tour";

const store = createStore({
  modules: {
    snackbar : SnackbarStore,
    session : SessionStore,
    user : UserStore,
    swipe : SwipeStore,
    files : FileStore,
    audit : AuditStore,
    tour: TourStore
  }
})

export default store;