import { Session } from '@/api/requests';
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path:'/login',
      name:'login',
      component: () => import("@/views/LoginPage.vue"),
    },
    {
      path:'/signup',
      name:'signup',
      component: () => import("@/views/SignupPage.vue")
    },
    {
      path:'/',
      name:'swipe',
      component: () => import("@/views/SwipePage.vue"),
      meta: { requiresAuth: true }
    },
    {
      path:'/selection',
      name:'selection',
      component: () => import("@/views/SelectionPage.vue"),
      meta: { requiresAuth: true }
    },
    {
      path:'/profile',
      name:'profile',
      component: () => import('@/views/ProfilePage.vue'),
      meta : { requiresAuth: true }
    },
    {
      path:'/intro',
      name:'intro',
      component: () => import("@/views/IntroPage.vue"),
      meta : { requiresAuth: true }
    },
    {
      path:'/warrant-intro',
      name:'warrant-intro',
      component: () => import("@/components/profile/WarrantIntro.vue"),
      meta : { requiresAuth: true }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import("@/views/ForgotPassword.vue")
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) return next();
  const store = useStore();
  if (store.getters.isLoggedIn) return next();
  const {canLogIn, pushToIntro} = await Session.loginRequest();
  if (canLogIn && !pushToIntro) return next();
  if (pushToIntro) return next({path: '/intro'})
  next({path: '/login'});
});

export default router;